import React, { useEffect, useState, useMemo } from 'react';
import { Globals } from '../Helpers/Functions';
import useAPI from '../hooks/useAPI';
import { GET_CUSTOMERS, ALERTS_OVERVIEW } from '../hooks/useAPI/actions';
import { handleWebserviceError } from '../Helpers/Functions';

// Variables used to enable or disable all alert-related options
const OFF = false
// const ON = true
export const ALERTS_TOGGLE = OFF

const Context = React.createContext({
    customers: {},
    setCustomers: () => {},
    alerts: {},
    setAlerts: () => {}
});

export function UtilsContext({ children }) {
    const [customers, setCustomers] = useState([]);
    const [alerts, setAlerts] = useState({"total_alerts":0,"buildings":[],"zones":[]});
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;
    const value = useMemo(
        () => ({ customers, setCustomers, alerts, setAlerts }),
        [customers, alerts]
    );

    useEffect(() => {
        if(Globals.user !== undefined && customers.length === 0) {
            callAPI(GET_CUSTOMERS(Globals.user.customer));
            if (ALERTS_TOGGLE) {
                callAPI(ALERTS_OVERVIEW);
            };
        }
    }, [Globals]);

    useEffect(() => {
        if(data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CUSTOMERS') {
            setCustomers(data);
        } else if (data != null && data.status_code === undefined && type === 'ALERTS_OVERVIEW') {
            setAlerts(data);
        }
    }, [data]);

    return(
        <Context.Provider value={ value }>
            {children}
        </Context.Provider>
    )
}

export default Context;
