import React, { useState, useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import DatePicker from '../Components/DatePicker';
import EdiText from 'react-editext'
import styled from 'styled-components'
import { CHANGE_BUILDING_ZONE_NAME } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import editNameSvg from '../Img/icons/edit-name.svg';
import cancelNameSvg from '../Img/icons/cancel-name.svg';
import confirmNameSvg from '../Img/icons/confirm-name.svg';

const StyledEdiText = styled(EdiText)`
    button {
        border-radius: 5px;
        padding: 0;
        margin: 0;
    }
    button:focus {outline:0;}
    button[editext="edit-button"] {
        padding: 0 15px;
        padding-top: 7px;
        border-color: #A6A6A6;
        background-color: #E6E6E6;
    }
    button[editext="edit-button"]::after {
        content: url(${editNameSvg});
        vertical-align: middle;
        margin-left: 5px;
    }
    button[editext="save-button"] {
        padding: 0 15px;
        padding-top: 7px;
        border-color: #B3FFD9;
        background-color: #E7FEF1;
        margin-left: 5px;
        width: max-content;
    }
    button[editext="save-button"]::after {
        content: url(${confirmNameSvg});
        vertical-align: middle;
        margin-left: 5px;
    }
    button[editext="cancel-button"] {
        margin: auto;
        padding: 7px 11px 0px 11px;
        border-color: #FFB3B3;
        background-color: #FDE8E9;
    }
    button[editext="cancel-button"]::after {
        content: url(${cancelNameSvg});
    }
    input, textarea {
        padding: 10px 15px;
        border-radius: 5px;
    }
    div[editext="view-container"] {
        justify-content: space-between;
    }
    div[editext="edit-container"] div {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
    `

function Navigation({ id, action, actionIcon, actionUrl, date, items, setDate, getCustomers, setCustomers, t }) {
    const { callAPI } = useAPI();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    let isDashBoard = (window.location.pathname.split('/')[1] === 'dashboard') || (window.location.pathname.split('/')[1] === 'energy');

    let canChangeBuildingName = (actionUrl === undefined && window.location.pathname.split('/')[1] === 'building')
    let canChangeZoneName = (window.location.pathname.split('/')[1] === 'dashboard') && (actionUrl.split('/')[0] !== 'settings')

    function changeName(new_name) {
        let temp = null
        if (!(items[0] === new_name || items[1] === new_name)) {
            if (canChangeBuildingName) {
                temp = getCustomers.buildings.map(customerBuilding => (customerBuilding.name === items[0]) ? {...customerBuilding, name: new_name} : customerBuilding);
                setCustomers({...getCustomers, buildings: temp})
            } else {
                temp = getCustomers.buildings.map(
                    customerBuilding => (customerBuilding.name === items[0]) ? {...customerBuilding, isOpen_force: true, zones: customerBuilding.zones.map(
                        customerZone => (customerZone.name === items[1]) ? {...customerZone, name: new_name} : customerZone
                    )} : customerBuilding);
                setCustomers({...getCustomers, buildings: temp})
            }
            callAPI(CHANGE_BUILDING_ZONE_NAME(id),
                {'name': new_name, 'type': (canChangeBuildingName) ? 'Building' : 'Zone'});
        }
    }

    useEffect(() => {
        function handleResize () {
            if (window.innerWidth > 768 && windowWidth <= 768) {
                setWindowWidth(window.innerWidth)
            } else if (window.innerWidth <= 768 && windowWidth > 768) {
                setWindowWidth(window.innerWidth)
            }
        }
        window.addEventListener('resize', handleResize)
    }, [window.innerWidth])

    return(
        <div className='toolbar d-flex pt-3 pb-2 mb-3'>
            <Breadcrumb className='breadcrumbs w-100 mr-3'>
                {
                    items.map((item, index) => {
                        let itemClass = ((items.length - 1) !== index) ? 'breadcrumb-item--inactive': '';
                        let itemActive = ((items.length - 1) === index);
                        return (
                            <Breadcrumb.Item
                                className={itemClass}
                                active={itemActive}
                                key={index}
                            >
                                {
                                    (canChangeBuildingName || (canChangeZoneName && index !== 0)) ? (
                                        (windowWidth > 768) ? (
                                            <StyledEdiText
                                                type='text'
                                                value={item}
                                                editButtonContent="Edit"
                                                saveButtonContent="Save"
                                                showButtonsOnHover
                                                hideIcons={true}
                                                onSave={v => changeName(v)}
                                            />
                                        ) : (
                                            <StyledEdiText
                                                type='text'
                                                value={item}
                                                editButtonContent="Edit"
                                                saveButtonContent="Save"
                                                hideIcons={true}
                                                onSave={v => changeName(v)}
                                            />
                                        )
                                    ) : (item)
                                }
                            </Breadcrumb.Item>
                        )
                    })
                }
            </Breadcrumb>
            {
                isDashBoard && (
                    <div className='btn-toolbar mt-3 mt-md-0 mr-3 preferences-container'>
                        <DatePicker
                            closable
                            onChange={setDate}
                            value={date}
                        />
                    </div>
                )
            }

            {
                (action) ? (
                    <div className='btn-toolbar mt-3 mt-md-0 preferences-container'>
                        <Link
                            to={actionUrl}
                            className='btn btn-orpheus-fill d-flex align-items-center'
                        >
                            <span className='pr-3'>{action}</span>
                            {actionIcon}
                        </Link>
                    </div>
                ) : false
            }
        </div>
    )
};

export default withTranslation()(Navigation);
