import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts/highcharts.js';
import HighchartsReact from 'highcharts-react-official';
import { withTranslation } from 'react-i18next';
import { calculateHoursDiff, handleWebserviceError, timeStringToFloat } from '../Helpers/Functions';
import { GET_CHART_OCCUPATION } from '../hooks/useAPI/actions';
import useAPI from '../hooks/useAPI';
import { ModuleTypes } from '../Helpers/ModuleTypes';
import { generateHoverGradient, generateOccupationWithGradients, generateOccupationWithSteps, resizeHoverGradients } from './ChartOccupation.helpers';

window.occupationInterval = null;
function ChartOccupation({ date, modules, t }) {
    let module = modules.find(module => (module.module_type === ModuleTypes.Occupancy));

    const [chartDimensions, setChartDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [chartData, setChartData] = useState({ chartDataKey: [], highcharts: undefined, module });
    const { callAPI, apiResult } = useAPI();
    const { data, type } = apiResult;

    function defineGradients(chart) {
        generateHoverGradient('hover0-open', chart, { endColor: 'rgba(0, 255, 195, .1)', stopColor: '#666666' }, .46)
        generateHoverGradient('hover25-open', chart, { endColor: 'rgba(0, 255, 195, .1)', stopColor: '#666666' }, .55)
        generateHoverGradient('hover50-open', chart, { endColor: 'rgba(0, 255, 195, .1)', stopColor: '#666666' }, .7)
        generateHoverGradient('hover75-open', chart, { endColor: 'rgba(0, 255, 195, .1)', stopColor: '#666666' }, .85)
        generateHoverGradient('hover0-close', chart, { endColor: 'rgba(200, 200, 200, .1)', stopColor: '#666666' }, .46)
        generateHoverGradient('hover25-close', chart, { endColor: 'rgba(200, 200, 200, .1)', stopColor: '#666666' }, .55)
        generateHoverGradient('hover50-close', chart, { endColor: 'rgba(200, 200, 200, .1)', stopColor: '#666666' }, .7)
        generateHoverGradient('hover75-close', chart, { endColor: 'rgba(200, 200, 200, .1)', stopColor: '#666666' }, .85)

        window.addEventListener('resize', () => setChartDimensions({ width: window.innerWidth, height: window.innerHeight }));
    }

    useEffect(() => {
        if (chartData.module !== undefined) {
            window.clearInterval(window.occupationInterval);
            callAPI(GET_CHART_OCCUPATION(chartData.module.id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            window.occupationInterval = window.setInterval(() => {
                callAPI(GET_CHART_OCCUPATION(chartData.module.id, `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`));
            }, 300000);
        } else {
            setChartData({ highcharts: {}, module });
        }

    }, [chartData.module, date]);

    useEffect(() => {
        module = modules.find(module => (module.module_type === ModuleTypes.Occupancy));
        setChartData({ highcharts: undefined, module });
    }, [modules]);

    useEffect(() => {
        if (data != null && data.status_code !== undefined) {
            handleWebserviceError(data);
        } else if (data != null && data.status_code === undefined && type === 'GET_CHART_OCCUPATION') {
            let decimalSchedule = data.highcharts.schedule.map(hour => Math.floor(timeStringToFloat(hour)));
            let hoursOpened = calculateHoursDiff(decimalSchedule[0], decimalSchedule[1]) + 1;
            decimalSchedule.push(decimalSchedule[1] < decimalSchedule[0]);
            setChartData({ ...chartData, chartDataKey: data.highcharts.occupancy ? data.highcharts.occupancy : data.highcharts.movements, decimalSchedule, hoursOpened, highcharts: data.highcharts });
        }
    }, [data]);

    if (chartData.highcharts !== undefined) {
        var occupationChart = {
            plotOptions: {
                pie: {
                    borderWidth: 1,
                    shadow: false
                },
                series: {
                    point: {
                        events: {
                            mouseOver: function () {
                                this.options.oldColor = this.color;

                                if(chartData.highcharts.capacity && this.custom.step === undefined) return;

                                if(chartData.highcharts.capacity && this.custom.step < 1) {
                                    let gradient = ''
                                    if(this.custom.step === 0) {
                                        gradient = this.custom.isOpened ? 'url(#hover0-open)' : 'url(#hover0-close)';
                                    } else if(this.custom.step === .25) {
                                        gradient = this.custom.isOpened ? 'url(#hover25-open)' : 'url(#hover25-close)';
                                    } else if(this.custom.step === .5) {
                                        gradient = this.custom.isOpened ? 'url(#hover50-open)' : 'url(#hover50-close)';
                                    } else if(this.custom.step === .75) {
                                        gradient = this.custom.isOpened ? 'url(#hover75-open)' : 'url(#hover75-close)';
                                    }

                                    this.graphic.attr("fill", gradient);
                                } else {
                                    this.graphic.attr("fill", "#666666");
                                    this.dataLabel !== undefined && this.dataLabel.css({ color: 'white' });
                                }
                            },
                            mouseOut: function () {
                                this.graphic.attr("fill", this.options.oldColor);
                                this.dataLabel !== undefined && this.dataLabel.css({ color: 'black' });
                            }
                        }
                    },
                    states: {
                        hover: {
                            halo: false
                        },
                        inactive: {
                            opacity: 1
                        }
                    }
                }
            },
            tooltip: {
                borderWidth: 0,
                formatter: function (tooltip) {
                    let htmlTooltip = `<table><tr><small>${this.point.custom.date}h</small></tr></br>`;
                    const excludedKeys = ['date', 'isOpened', 'step'];
                    for (let k in this.point.custom) {
                        if (!excludedKeys.includes(k) && this.point.custom[k] !== null) {
                            htmlTooltip += `<tr>${t('common.' + k + '_quant')}: <b>${this.point.custom[k]}</b></tr></br>`;
                        }
                    }
                    return htmlTooltip;
                },
                useHTML: true
            },
            title: false,
            series: [
                {
                    type: 'pie',
                    data: [
                        {
                            color: '#D8D8D8',
                            name: t('common.close'),
                            y: (24 - chartData.hoursOpened) * 7
                        },
                        {
                            color: '#00FFC3',
                            name: t('common.open'),
                            y: chartData.hoursOpened * 7
                        }
                    ],
                    dataLabels: {
                        enabled: false
                    },
                    startAngle: (Math.floor(chartData.decimalSchedule[1]) - 24) * 15 + 15,
                    size: '40%',
                    zIndex: 2,
                    enableMouseTracking: false
                },
                {
                    type: 'pie',
                    data: (chartData.highcharts.capacity && data.highcharts.occupancy) ? generateOccupationWithSteps({ ...chartData.highcharts, ...chartData }) : generateOccupationWithGradients({ ...chartData.highcharts, ...chartData }),
                    showInLegend: false,
                    dataLabels: {
                        color: '#42484A',
                        distance: -20,
                        style: {
                            fontFamily: 'Avenir, sans-serif',
                            fontSize: '16px',
                            textOutline: 0
                        },
                    }
                }
            ]
        };
    }

    if (chartData.highcharts !== undefined && occupationChart !== undefined && chartData.chartDataKey.length > 0) {
        return (
            <div>
                <div className='chart-container'>
                    <h6 className='chart-title pt-3 ml-3'>{t('common.' + (chartData.highcharts.occupancy ? "occupancy" : "movements") + '_title')}</h6>
                    <HighchartsReact
                        key={`occupation-${chartData.module.id}-${chartDimensions.width}-${chartDimensions.height}`}
                        callback={defineGradients}
                        style={{ height: 'calc(100% - 42px)' }}
                        highcharts={Highcharts}
                        options={occupationChart}
                    />
                    <div className="occupation-leyend-group rotated-leyend">
                        <div className="occupation-leyend-item">
                            <p>{t('common.open')}</p>
                            <div className="leyend-point" style={{ background: 'rgba(0, 255, 195, 1)' }}></div>
                        </div>
                        <div className="occupation-leyend-item">
                            <p>{t('common.close')}</p>
                            <div className="leyend-point" style={{ background: 'rgba(200, 200, 200, 1)' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return false;
    }
}

export default withTranslation()(ChartOccupation);
