function calculateHoursOpened(schedule) {
    let hoursOpened = [];

    if (schedule[2]) {
        let i = schedule[0];
        while (i < 24) {
            hoursOpened.push(i);
            i++;
        }
        let second = schedule[1];
        while (second > -1) {
            hoursOpened.push(second);
            second--;
        }
    } else {
        let hour = schedule[0];
        let i = schedule[1];
        while (hour <= i) {
            hoursOpened.push(i);
            i--;
        }
    }

    return hoursOpened;
}

export function generateHoverGradient(id, chart, colors, stop) {
    const { endColor, stopColor } = colors;
    const r = chart.renderer;

    const gradient = r.createElement('radialGradient')
        .attr({
            class: 'generated-hover-gradient',
            id,
            cx: chart.plotSizeX * .5,
            cy: chart.plotSizeY * .5,
            r: Math.min(chart.plotBox.height, chart.plotBox.width) * .5 - chart.plotBox.x,
            gradientUnits: 'userSpaceOnUse',
        })
        .add(r.defs);

    r.createElement('stop').attr({ offset: '0', 'stop-color': stopColor }).add(gradient);
    r.createElement('stop').attr({ offset: stop, 'stop-color': stopColor }).add(gradient);
    r.createElement('stop').attr({ offset: stop + 0.01, 'stop-color': endColor }).add(gradient);
    r.createElement('stop').attr({ offset: '1', 'stop-color': endColor }).add(gradient);
}

export function generateOccupationWithGradients(chartData) {
    const { chartDataKey, date, decimalSchedule, threshold } = chartData;
    const quantKey = chartData.occupancy ? 'occupancy': 'movements';
    if(!chartDataKey) return [];

    const hoursOpened = calculateHoursOpened(decimalSchedule);
    let pies = [];

    chartDataKey.map((occupancy, hour) => {
        let isOpened = (hoursOpened.includes(hour));
        let gradient = chartDataKey[hour] / threshold;
        let parsedGradient = (gradient === 0) ? 0.1 : gradient * 0.70 + 0.20;
        let custom = { date: date[hour] };
        custom[quantKey] = occupancy;

        return pies.push({
            color: (isOpened) ? `rgba(0, 255, 195, ${parsedGradient})` : `rgba(200, 200, 200, ${parsedGradient})`,
            custom,
            name: hour.toString(),
            y: 1
        });
    });

    return pies;
}

export function generateOccupationWithSteps(chartData) {
    const steps = [0, .25, .5, .75, 1]
    const stepsGradients = [.46, .55, .7, .85, 1]
    const { chartDataKey, capacity, date, decimalSchedule } = chartData;
    const quantKey = chartData.occupancy ? 'occupancy': 'movements';
    if(!chartDataKey) return [];

    const hoursOpened = calculateHoursOpened(decimalSchedule);
    let pies = [];
    chartDataKey.map((occupancy, hour) => {
        let isOpened = (hoursOpened.includes(hour));
        let minColor = (isOpened) ? 'rgba(0, 255, 195, .1)' : 'rgba(200, 200, 200, .1)';
        let color = (isOpened) ? 'rgb(0, 255, 195)' : 'rgb(200, 200, 200)';
        let nearestStep;

        if(occupancy > 0 && occupancy > capacity) {
            nearestStep = 1;
        } else if (occupancy > 0) {
            nearestStep = steps.find(step => step >= occupancy / capacity);
        }

        let step = nearestStep ? steps.indexOf(nearestStep) : undefined;
        let custom = { date: date[hour], isOpened, step: nearestStep };
        custom[quantKey] = occupancy;

        return pies.push({
            color: step ? {
                radialGradient: { cx: 0.5, cy: 0.5, r: 0.5 },
                stops: [
                    [0, color],
                    [stepsGradients[step], color],
                    [stepsGradients[step] + 0.01, minColor],
                    [1, minColor]
                ]
            } : minColor,
            custom,
            name: hour.toString(),
            y: 1
        });
    });

    return pies;
}
